import P140NotFoundPage from '$templates/pages/components/P140NotFoundPage';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetStaticProps } from 'next';
import { ReactElement } from 'react';
import DynamicLayout from '~/templates/layouts';
import { DynamicPageProps } from '~/templates/pages';
import { prefetchStaticData } from '~/templates/pages/utils';

export const getStaticProps: GetStaticProps<DynamicPageProps> = async ({ locale }) => {
    const queryClient = new QueryClient();

    // Fetch frame, page, translations
    await prefetchStaticData({ queryClient, locale, slug: ['404'] });
    const dehydratedState = dehydrate(queryClient);

    // Clear the cache to avoid high memory consumption on server
    queryClient.clear();

    return {
        revalidate: 30,
        props: {
            dehydratedState,
        },
    };
};

/**
 * The frontend has no awareness over what pages exist so we rely on the
 * CMS to provide us with the 404 page. In cases the page is not found the CMS will
 * return the configured 404 page.
 */
export default function NotFoundPage() {
    return <P140NotFoundPage />;
}

NotFoundPage.getLayout = function getLayout(page: ReactElement) {
    return <DynamicLayout>{page}</DynamicLayout>;
};
