import { UmbracoTypes } from '~/lib/data-contract';
import { usePage } from '~/services';
import { MaxWidth } from '~/shared/components/MaxWidth';
import DynamicBlocks from '~/templates/blocks';

export default function P140NotFoundPage() {
    const { data: page } = usePage<UmbracoTypes.IP140NotFoundPage>();

    return (
        <MaxWidth>
            <DynamicBlocks elements={page?.pageElements as UmbracoTypes.IModules[]} />
        </MaxWidth>
    );
}
